import {  } from 'squint-cljs/string.js'
import { set, atom, dec, reset_BANG_, range, conj, println, nth, contains_QMARK_, swap_BANG_, vector, mapv, subvec, inc, str, count, apply, map_indexed, deref, re_matches } from 'squint-cljs/core.js'
import { split as str_split, join as str_join } from 'squint-cljs/string.js'
println("=== Play Wordle (println) ===");
var board_state = atom([])
;
var counter = atom(0)
;
var attempt = atom(0)
;
var word_of_the_day = atom("hello")
;
var write_letter = function (cell, letter) {
return cell["textContent"] = letter;
;
}
;
var make_cell = function () {
let cell1 = document.createElement("div");
cell1["className"] = "cell";
return cell1;
}
;
var make_board = function (n) {
println("make a board of", n, "cells");
let board2 = document.createElement("div");
board2["className"] = "board";
let seq__37 = range(n);
let chunk__48 = null;
let count__59 = 0;
let i__610 = 0;
while(true){
if ((i__610 < count__59)) {
let i11 = _nth(chunk__48, i__610);
println("iteration", i11);
null;
let G__12 = seq__37;
let G__13 = chunk__48;
let G__14 = count__59;
let G__15 = unchecked_inc(i__610);
seq__37 = G__12;
chunk__48 = G__13;
count__59 = G__14;
i__610 = G__15;
continue;
} else {
let temp__24986__auto__16 = ((seq__37["length"] > 0)) ? (seq__37) : (null);
if (temp__24986__auto__16) {
let seq__317 = temp__24986__auto__16;
if (false) {
let c__25359__auto__18 = chunk_first(seq__317);
let G__19 = chunk_rest(seq__317);
let G__20 = c__25359__auto__18;
let G__21 = count(c__25359__auto__18);
let G__22 = 0;
seq__37 = G__19;
chunk__48 = G__20;
count__59 = G__21;
i__610 = G__22;
continue;
} else {
let i23 = (seq__317[0]);
println("iteration", i23);
null;
let G__24 = seq__317.slice(1);
let G__25 = null;
let G__26 = 0;
let G__27 = 0;
seq__37 = G__24;
chunk__48 = G__25;
count__59 = G__26;
i__610 = G__27;
continue;
}}};break;
}
;
let seq__2832 = str_split(str_join("-", range(n)), "-");
let chunk__2933 = null;
let count__3034 = 0;
let i__3135 = 0;
while(true){
if ((i__3135 < count__3034)) {
let _36 = _nth(chunk__2933, i__3135);
let cell37 = make_cell();
swap_BANG_(board_state, conj, cell37);
board2.appendChild(cell37);
null;
let G__38 = seq__2832;
let G__39 = chunk__2933;
let G__40 = count__3034;
let G__41 = unchecked_inc(i__3135);
seq__2832 = G__38;
chunk__2933 = G__39;
count__3034 = G__40;
i__3135 = G__41;
continue;
} else {
let temp__24986__auto__42 = ((seq__2832["length"] > 0)) ? (seq__2832) : (null);
if (temp__24986__auto__42) {
let seq__2843 = temp__24986__auto__42;
if (false) {
let c__25359__auto__44 = chunk_first(seq__2843);
let G__45 = chunk_rest(seq__2843);
let G__46 = c__25359__auto__44;
let G__47 = count(c__25359__auto__44);
let G__48 = 0;
seq__2832 = G__45;
chunk__2933 = G__46;
count__3034 = G__47;
i__3135 = G__48;
continue;
} else {
let _49 = (seq__2843[0]);
let cell50 = make_cell();
swap_BANG_(board_state, conj, cell50);
board2.appendChild(cell50);
null;
let G__51 = seq__2843.slice(1);
let G__52 = null;
let G__53 = 0;
let G__54 = 0;
seq__2832 = G__51;
chunk__2933 = G__52;
count__3034 = G__53;
i__3135 = G__54;
continue;
}}};break;
}
;
return board2;
}
;
var get_letter = function (cell) {
return cell["textContent"];
}
;
var color_cell = function (idx, cell) {
let color55 = function (el, color) {
return el["style"]["backgroundColor"] = color;
;
};
let letter56 = get_letter(cell);
if ((letter56 === nth(deref(word_of_the_day), idx))) {
return color55(cell, "green");} else {
if (contains_QMARK_(set(deref(word_of_the_day)), letter56)) {
return color55(cell, "aqua");} else {
if ("else") {
return color55(cell, "#333333");} else {
return null;}}}
}
;
var check_solution = function (cells) {
let seq__5761 = map_indexed(vector, cells);
let chunk__5862 = null;
let count__5963 = 0;
let i__6064 = 0;
while(true){
if ((i__6064 < count__5963)) {
let vec__6568 = _nth(chunk__5862, i__6064);
let idx69 = nth(vec__6568, 0, null);
let cell70 = nth(vec__6568, 1, null);
color_cell(idx69, cell70);
null;
let G__71 = seq__5761;
let G__72 = chunk__5862;
let G__73 = count__5963;
let G__74 = unchecked_inc(i__6064);
seq__5761 = G__71;
chunk__5862 = G__72;
count__5963 = G__73;
i__6064 = G__74;
continue;
} else {
let temp__24986__auto__75 = ((seq__5761["length"] > 0)) ? (seq__5761) : (null);
if (temp__24986__auto__75) {
let seq__5776 = temp__24986__auto__75;
if (false) {
let c__25359__auto__77 = chunk_first(seq__5776);
let G__78 = chunk_rest(seq__5776);
let G__79 = c__25359__auto__77;
let G__80 = count(c__25359__auto__77);
let G__81 = 0;
seq__5761 = G__78;
chunk__5862 = G__79;
count__5963 = G__80;
i__6064 = G__81;
continue;
} else {
let vec__8285 = (seq__5776[0]);
let idx86 = nth(vec__8285, 0, null);
let cell87 = nth(vec__8285, 1, null);
color_cell(idx86, cell87);
null;
let G__88 = seq__5776.slice(1);
let G__89 = null;
let G__90 = 0;
let G__91 = 0;
seq__5761 = G__88;
chunk__5862 = G__89;
count__5963 = G__90;
i__6064 = G__91;
continue;
}}};break;
}
;
return (apply(str, mapv(get_letter, cells)) === str(deref(word_of_the_day)));
}
;
var user_input = function (key) {
let start92 = (5 * deref(attempt));
let end93 = (5 * (deref(attempt) + 1));
if (re_matches(/[a-z]/, key) && (deref(counter) < end93)) {
write_letter(nth(deref(board_state), deref(counter)), key);
return swap_BANG_(counter, inc);} else {
if ((key === "backspace") && (deref(counter) > start92)) {
swap_BANG_(counter, dec);
return write_letter(nth(deref(board_state), deref(counter)), "");} else {
if ((key === "enter") && (deref(counter) === end93)) {
if (check_solution(subvec(deref(board_state), start92, end93))) {
alert("You won")};
return swap_BANG_(attempt, inc);} else {
return null;}}}
}
;
if ((typeof listener !== 'undefined')) {
null} else {
var listener = atom(null)
};
var unmount = function () {
println("unmount the app component");
document.removeEventListener("keydown", deref(listener));
let app94 = document.getElementById("app");
return app94["innerHTML"] = "";
;
}
;
var mount = function () {
println("mount the app component");
let app95 = document.getElementById("app");
let board96 = make_board(30);
let input_listener97 = function (e) {
return user_input(e["key"].toLowerCase());
};
app95.appendChild(board96);
reset_BANG_(listener, input_listener97);
return document.addEventListener("keydown", input_listener97);
}
;
mount();
null;

export { make_cell, counter, word_of_the_day, board_state, listener, user_input, check_solution, color_cell, attempt, unmount, mount, make_board, write_letter, get_letter }
